<template>
  <a-page-header @back="() => $router.go(-1)" title="IMPORTAR INFORMACIÓN" class="pt-0 px-0">
    <template #extra>
      <a-breadcrumb>
        <a-breadcrumb-item><router-link to="/menu">Import./Export.</router-link></a-breadcrumb-item>
        <a-breadcrumb-item>Importar información</a-breadcrumb-item>
      </a-breadcrumb>
    </template>
  </a-page-header>

  <div class="flex flex-wrap justify-between mb-8">
    <a-alert class="md:w-10/12">
      <template #description>
        <div class="mb-2">
          En esta ventana se importa información exportada desde otro establecimiento, generalmente
          desde el central hacia un sistema local
        </div>

        <p>✅ Formatos válidos .csv y .zip</p>
        <p>✅ Se actualiza al último subido</p>
        <p>✅ Ejemplo de archivo: reg_paciente.csv</p>

        <p class="italic text-gray-400 my-2">
          Tener en cuenta que si dentro del archivo contiene fechas erróneas o no coinciden con el
          nombre del archivo (si termina en _2021_1.csv y dentro del archivo las fechas son
          2021/07/01 - de julio cuando debería ser de enero) mostrará un error.
        </p>
      </template>
    </a-alert>
    <button @click="descargarPlantilla" type="button" class="text-center px-3 md:w-2/12">
      <div class="text-3xl text-green-600">
        <DownloadOutlined />
      </div>
      <p>
        Descargar <br />
        plantilla
      </p>
    </button>
  </div>

  <div class="lg:flex lg:gap-4">
    <a-upload-dragger
      v-model:fileList="fileList"
      accept=".csv,.zip"
      name="file"
      :multiple="true"
      :action="urlAction"
      :headers="headers"
      :before-upload="beforeUpload"
      @change="onChangeUpload"
    >
      <p class="ant-upload-drag-icon">
        <inbox-outlined></inbox-outlined>
      </p>
      <p class="ant-upload-text">Haz click o arrastra en este área para subir</p>
      <p class="ant-upload-hint p-8">
        Se aceptan csv (delimitado por comas) y zip. En zip se puede enviar más de 1 archivo.
      </p>
    </a-upload-dragger>

    <div class="mt-8 lg:m-0">
      <div class="overflow-x-auto w-full">
        <a-table
          :columns="columns"
          :data-source="data"
          size="small"
          :pagination="false"
          rowKey="idimportacion"
          :rowClassName="(record, index) => (index % 2 === 0 ? 'bg-white' : 'bg-gray-100')"
        >
          <template #rangepages="{ index }">
            <span> {{ (currentPage - 1) * 10 + index + 1 }} </span>
          </template>

          <template #estado="{ text: estado }">
            <a-tag v-if="estado === '2'" color="success">
              <template #icon>
                <CheckCircleOutlined />
              </template>
              exitoso
            </a-tag>

            <a-tag v-if="estado === '1'" color="processing">
              <template #icon>
                <SyncOutlined :spin="true" />
              </template>
              procesando
            </a-tag>

            <a-tag v-if="estado === '0'" color="error">
              <template #icon>
                <CloseCircleOutlined />
              </template>
              error
            </a-tag>
          </template>
        </a-table>
      </div>

      <footer
        class="flex flex-col gap-4 items-center justify-between pb-6 pt-5 border-t-2 md:flex-row"
      >
        <div class="flex">
          <a-pagination
            v-model:current="currentPage"
            v-model:pageSize="pageSize"
            :total="totalDocs"
          />
        </div>

        <p class="text-gray-300 font-semibold">Archivos cargados las últimas 24 horas</p>
      </footer>
    </div>
  </div>
</template>

<script>
import { ref, watch, computed, toRaw, onMounted } from "vue";
import router from "@/router";
import { message, notification } from "ant-design-vue";
import { useStore } from "vuex";
import { columns, breadcrumb } from "./utilsCargaBaseDatos";
import { numberWithCommas } from "@/utils";
import CargaBaseDatosApi from "@/api/cargaBaseDatos";
import UsuariosApi from "@/api/user";
import { getUsuario } from "@/utils/auth";
import ExportarApi from "@/api/exportar";
import moment from "moment";
import {
  InboxOutlined,
  DownloadOutlined,
  CheckCircleOutlined,
  SyncOutlined,
  CloseCircleOutlined
} from "@ant-design/icons-vue";

export default {
  components: {
    // icons
    InboxOutlined,
    DownloadOutlined,
    CheckCircleOutlined,
    SyncOutlined,
    CloseCircleOutlined
  },
  data: function () {
    return {
      intervalid1: ""
    };
  },
  methods: {
    todo: function () {
      this.intervalid1 = setInterval(() => {
        this.fetchData();
      }, 3000);
    }
  },
  mounted: function () {
    this.todo();
  },
  unmounted: function () {
    clearInterval(this.intervalid1);
  },
  setup() {
    const pageSize = ref(10);
    const store = useStore();
    const isLoading = ref(false);
    const data = ref([]);
    const tablas = ref([]);
    const currentPage = ref(1);
    const totalDocs = ref(null);
    const urlPlantilla = ref("");
    // const urlAction = ref("http://157.230.91.190/api/data/V1");
    const urlAction = process.env.VUE_APP_UPLOAD_API + "/carga_base";
    const { establecimiento } = store.state.user.usuario;

    const beforeUpload = (file) => {
      const isZipOrCSV =
        file.type === "application/zip" ||
        file.type === "text/csv" ||
        file.type === "application/x-zip-compressed" ||
        file.type === "application/vnd.ms-excel";

      if (!isZipOrCSV) {
        // message.error("You can only upload JPG file!");
        notification.error({
          message: "Operación inválida",
          description: "Solo se aceptan archivos de tipo .zip y .csv"
        });
      }

      var name = file.name.split(".")[0];
      var archivo = 1;

      var isnominaltrama = file.name.split("_");

      // tablas.value.forEach((element) => {
      //   console.log(element);
      //   if (element.Tables_in_sivi_migr == name.toLowerCase()) {
      //     archivo = 1;
      //   }
      // });

      // if (isnominaltrama.length > 0) {
      //   if (
      //     isnominaltrama[0].toLowerCase() == "nominal" &&
      //     isnominaltrama[1].toLowerCase() == "trama" &&
      //     isnominaltrama[2].toLowerCase() == "nuevo" &&
      //     isnominaltrama[3].toLowerCase() == "general"
      //   )
      //     archivo = 1;
      // }

      // console.log(isnominaltrama[0].toLowerCase());

      if (archivo == 0) {
        notification.error({
          message: "Operación inválida",
          description: "Error al cargar el archivo."
        });

        return false;
      }

      // const isLt2M = file.size / 1024 / 1024 < 2;

      // if (!isLt2M) {
      //   message.error("Image must smaller than 2MB!");
      // }

      // return isJpgOrPng && isLt2M;
      return isZipOrCSV;
    };

    const obtieneEnlacePlantilla = () => {
      CargaBaseDatosApi.getEnlacePlantilla("PLANTILLA_CARGADB")
        .then((res) => {
          urlPlantilla.value = res.data[0].valor;
        })
        .catch((err) => console.log(err));
    };

    const onChangeUpload = (info) => {
      const status = info.file.status;

      if (status !== "uploading") {
        console.log(info.file, info.fileList);
        if (info.file.response?.errores?.length) {
          notification.error({
            message: "Operación inválida",
            // description: info.file.response?.errores[0].mensaje || "error al subir"
            description: "Error al cargar el archivo."
          });
        }
      }

      if (status === "done") {
        message.success(`${info.file.name} Archivo agregado correctamente.`);
      } else if (status === "error") {
        message.error(`${info.file.name} Falló el subir el archivo.`);
      }
    };

    const fetchData = () => {
      isLoading.value = true;
      CargaBaseDatosApi.getAll(toRaw(currentPage.value), getUsuario(), "3")
        .then((response) => {
          data.value = response.data.docs;
          totalDocs.value = response.data.totalDocs;
        })
        .catch((err) => console.log(err))
        .finally(() => (isLoading.value = false));
    };

    watch(currentPage, () => {
      fetchData();
    });

    onMounted(() => {
      UsuariosApi.userLogAdd("Import./Export.", "Importar", establecimiento)
        .then(async () => {})
        .catch((err) => console.log(err));

      ExportarApi.getAllTablas()
        .then(async (response) => {
          tablas.value = response.data;
        })
        .catch((err) => console.log(err))
        .finally();
      fetchData();
      obtieneEnlacePlantilla();
    });

    const datosFormated = computed(() =>
      data.value.map((d) => {
        return {
          ...d,
          totalregistros_f: numberWithCommas(d.totalregistros),
          fechaingreso_f: moment(d.fechaingreso).format("DD/MM/YYYY h:mm:ss a")
        };
      })
    );

    const descargarPlantilla = () => {
      window.open(urlPlantilla.value, "_blank");
    };

    return {
      goBack: () => router.back(),
      fileList: ref([]),
      routes: breadcrumb,
      onChangeUpload,
      headers: {
        authorization: "Bearer " + store.getters.token
      },
      data: datosFormated,
      isLoading,
      fetchData,
      currentPage,
      totalDocs,
      pageSize,
      columns,
      obtieneEnlacePlantilla,
      urlAction,
      descargarPlantilla,
      urlPlantilla,
      beforeUpload
    };
  }
};
</script>

<style></style>
