import { post } from "./base";

const API_SERVICE_QUERY_TABLE = "/api/Service/V1/showtables";
const API_SERVICE_QUERY = "/api/Service/V1/query";

export default {
  getAll: (payload) => {
    const request = {
      _type: 1,
      _table: "undefined",
      _petitions: [
        {
          name: "@buscar_AND",
          value: `${payload.search}`,
          type: "string"
        }
      ]
    };

    return post(API_SERVICE_QUERY_TABLE, request);
  },

  getAllTablas: () => {
    const request = {
      _type: 1,
      _table: "undefined",
      _petitions: [
        {
          name: "_script",
          value: "web_seg_select_table_sivi_migr",
          type: "string"
        }
      ]
    };

    return post(API_SERVICE_QUERY, request);
  }
};
